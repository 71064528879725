import http from "./http"

/** 
  @name { 电子回单列表 } 
  @params { token} 
  @data {
    "id": 803,                                          回单id
    "accp_txno": "2022060265084134",                    回单编号
    "txn_seqno": "TX-FwIuZmHqiqDlUxM",                  系统单号
    "created_at": "2022-06-02 09:58:59",                回单时间
    "money": "55330.00",                                回单金额
    "state": 1,                                         状态 支付类型 1网银 2支付宝 3微信
    "type": 3,                                          1充值 2购买 3提现 4红包 5售出
    "receipt_state": 0                                  回单状态 1已申请 0未申请
  }
*/
export const $apiInvoiceList = (data) =>http({ method: 'post', url:'/ele/rece/list',params: data }) 


/**
  @name { 电子回单申请 } 
  @params { token, receipt_id:"回单id" } 
  @data {
    "oid_partner": "2022022402400367", 
    "receipt_accp_txno": "2022060668573542", 
    "ret_code": "0000", 
    "ret_msg": "交易成功", 
    "token": "cfba8c770a00c42317c92740aba71d16", 
    "total_amount": "3200.00", 
    "trade_accp_txno": "2022060668573542", 
    "txn_seqno": "GXBKF67431654507439" 
  }
*/
export const $apiInvoiceApply = (data) =>  http({ method: 'post', url:'/ele/rece/apply',params: data })


/**
  @name { 电子回单下载 } 
  @params { 
    token, 
    token_receipt:	    申请接口返回参数, 
    receipt_accp_txno:  申请接口返回参数, 
    receipt_state:      1 为申请下载 0为直接下载 
  } 
*/
export const $apiInvoiceDownload = (data) => http({ method: 'post', url:'/ele/rece/download',params: data }) 


